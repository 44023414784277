<template>
  <div class="animated fadeIn">
    <b-card header="New Machine" class="card-columns cols-2">
      <b-form @submit.prevent="create" @reset="reset">
        <picture-input 
        ref="pictureInput" 
        @change="onChange" 
        width="300" 
        height="300" 
        margin="16" 
        accept="image/jpeg,image/png" 
        size="10" 
        buttonClass="btn btn-primary button"
        :removable="true"
        :customStrings="{
          upload: '<h1>Bummer!</h1>',
          drag: 'Click here to upload picture'
        }"></picture-input>
        <table class="table-m">
          <tr>
            <td>{{ $t('message.type') }}</td>
            <td>
              <b-form-select 
                v-model="type" 
                :options="machineType" 
                text-field="name"
                value-field="id"
                @input = "getMachineModel()"
              />
            </td>
          </tr>
          <tr>
            <td>{{ $t('message.machineModel') }}</td>
            <td>
              <b-form-select 
                v-model="machineData.modelId" 
                :options="machineModel" 
                text-field="name"
                value-field="id"
                required
              />
            </td>
          </tr>
          <tr>
            <td>{{ $t('message.user') }}</td>
            <td>
              <b-form-select 
                v-model="machineData.userId" 
                :options="userList" 
                text-field="userName"
                value-field="userId"
              />
            </td>
          </tr>
          <tr>
            <td>{{ $t('message.simProvider') }}</td>
            <td>
              <b-form-select 
                v-model="provider" 
                :options="ProviderList" 
                text-field="name"
                value-field="id"
                @input="getSim()"
              />
            </td>
          </tr>
          <tr>
            <td>{{ $t('message.simSerial') }}</td>
            <td>
              <b-form-select 
                v-model="machineData.simId" 
                :options="simList" 
                text-field="serial"
                value-field="id"
                required
              />
            </td>
          </tr>
          <tr>
            <td>{{ $t('message.machineName') }}</td>
            <td><b-form-input v-model="machineData.name" required/></td>
          </tr>
          <tr>
            <td>{{ $t('message.machineSerial') }}</td>
            <td><b-form-input v-model="machineData.serial" required/></td>
          </tr>
          <tr>
            <td>{{ $t('message.gsmSerial') }}</td>
            <td><b-form-input v-model="machineData.gsmSerial" required/></td>
          </tr>
          <tr>
            <td>{{ $t('message.coinAcceptor') }}</td>
            <td>
              <b-form-select 
                v-model="selectedData.coinAcceptor" 
                :options="coinAcceptorList" 
                text-field="model"
                value-field="value"
                required
                @input="selectCoinAcc"
              />
            </td>
          </tr>
          <tr>
            <td>{{ $t('message.banknoteAcceptor') }}</td>
            <td>
              <b-form-select 
                v-model="selectedData.billAcceptor" 
                :options="billAcceptorList" 
                text-field="model"
                value-field="value"
                required
                @input="selectBillAcc"
              />
            </td>
          </tr>
        </table>
        <div align="right">
          <b-button @click="reset" variant="danger" size="lg">{{ $t('message.back') }}</b-button>&nbsp;
          <b-button @click="create" variant="primary" size="lg">{{ $t('message.create') }}</b-button>
          <br></br><h4 class='invalidlogin' style="color:red; display:none;">{{ $t('message.cannotCreateMachine') }}</h4>
        </div>
        
      </b-form>
    </b-card>
  </div>
</template>

<script>
import PictureInput from 'vue-picture-input'
import cSwitch from '../../components/Switch'
import webServices from '../../script'
export default {
  name: 'NewMachine',
  components: {
    cSwitch,
    PictureInput
  },
  data () {
    return {
      machineData: {},
      machineType: [],
      machineModel: [],
      userList: [],
      coinAcceptorList: [],
      billAcceptorList: [],
      selectedData: {},
      type: '',
      provider: '',
      ProviderList: [
        {id: 'TRUE', name: 'TRUE'},
        {id: 'AIS', name: 'AIS'},
        {id: 'DTAC', name: 'DTAC'},
        {id: 'CAT', name: 'CAT'},
        {id: 'TOT', name: 'TOT'}
      ],
      simList: []
    }
  },
  methods: {
    onChange () {
      if (this.$refs.pictureInput.image) {
        var image = this.$refs.pictureInput.file
        const formData = new FormData()
        formData.append('image', image)
        // webServices.uploadImage(formData).then((res) => {
        //  console.log(res.data)
        //  this.newProduct.image = res.data.filename
        // }).catch((error) => {
        //  console.log(error)
        // })
      } else {
        this.$toast.error({
          title: 'ERROR',
          message: 'Can not upload file!'
        })
      }
    },
    // ////////////////////////////////////////////////////////
    // get machine details
    getMachineType () {
      webServices.getMachineType().then((res) => {
        this.machineType = res.data
      }).catch((err) => {
        console.log('ERROR @MACHINE TYPE')
        console.log(err)
        const errorText = {
          error: err.response.data,
          code: err.response.status,
          text: err.response.statusText
        }
        this.$toast.error({
          title: 'ERROR',
          message: JSON.stringify(errorText)
        })
      })
    },
    getMachineModel () {
      this.$Progress.start()
      webServices.getMachineModels(this.type).then((res) => {
        this.$Progress.finish()
        this.machineModel = res.data
      }).catch((err) => {
        this.$Progress.fail()
        console.log(err)
        console.log('error @model')
        const errorText = {
          error: err.response.data,
          code: err.response.status,
          text: err.response.statusText
        }
        this.$toast.error({
          title: 'ERROR',
          message: JSON.stringify(errorText)
        })
      })
    },
    getUserList () {
      webServices.getUserList('all').then((res) => {
        this.userList = res.data
      }).catch((err) => {
        console.log(err)
        console.log('error @user')
        const errorText = {
          error: err.response.data,
          code: err.response.status,
          text: err.response.statusText
        }
        this.$toast.error({
          title: 'ERROR',
          message: JSON.stringify(errorText)
        })
      })
    },
    getSimList () {
      webServices.getSimList().then((res) => {
        this.simList = res.data
      }).catch((err) => {
        console.log(err)
        console.log('error @simlist')
        const errorText = {
          error: err.response.data,
          code: err.response.status,
          text: err.response.statusText
        }
        this.$toast.error({
          title: 'ERROR',
          message: JSON.stringify(errorText)
        })
      })
    },
    getSim () {
      webServices.getSim(this.provider).then((res) => {
        this.simList = res.data
      }).catch((err) => {
        console.log(err)
        console.log('error @sim')
        const errorText = {
          error: err.response.data,
          code: err.response.status,
          text: err.response.statusText
        }
        this.$toast.error({
          title: 'ERROR',
          message: JSON.stringify(errorText)
        })
      })
    },
    getCoin () {
      webServices.getCoin().then((res) => {
        this.coinAcceptorList = res.data
        for (var i = 0; i < this.coinAcceptorList.length; i++) {
          this.coinAcceptorList[i].model = this.coinAcceptorList[i].value.model
        }
      }).catch((err) => {
        console.log(err)
        console.log('error @coin')
        const errorText = {
          error: err.response.data,
          code: err.response.status,
          text: err.response.statusText
        }
        this.$toast.error({
          title: 'ERROR',
          message: JSON.stringify(errorText)
        })
      })
    },
    getBill () {
      webServices.getBill().then((res) => {
        this.billAcceptorList = res.data
        for (var i = 0; i < this.billAcceptorList.length; i++) {
          this.billAcceptorList[i].model = this.billAcceptorList[i].value.model
        }
      }).catch((err) => {
        console.log(err)
        console.log('error @bill')
        const errorText = {
          error: err.response.data,
          code: err.response.status,
          text: err.response.statusText
        }
        this.$toast.error({
          title: 'ERROR',
          message: JSON.stringify(errorText)
        })
      })
    },
    selectCoinAcc () {
      this.machineData.coinAcceptor = this.selectedData.coinAcceptor.id
    },
    selectBillAcc () {
      this.machineData.billAcceptor = this.selectedData.billAcceptor.id
    },
    create () {
      this.$Progress.start()
      console.log(this.machineData)
      webServices.createMachineV2(this.machineData).then((res) => {
        this.$Progress.finish()
        // console.log(res.data)
        if (res.data.success) {
          this.$toast.success({
            title: 'SUCCESS',
            message: 'Machine has been created'
          })
          this.$router.push('/machine/machines')
        } else {
          const errorText = {
            errorCode: res.data.errorCode,
            errorText: res.data.errorText
          }
          this.$toast.error({
            title: 'ERROR',
            message: JSON.stringify(errorText)
          })
        }
      }).catch((error) => {
        this.$Progress.fail()
        if (err.response.status === 401) {
          webServices.tokenExpire()
        }
        console.log(error)
        this.$toast.warn({
          title: 'ERROR',
          message: 'Can not create new machine'
        })
      })
    },
    reset () {
      this.$router.push('/machine/machines')
    }
  },
  mounted () {
    this.getMachineType()
    this.getUserList()
    this.getSimList()
    this.getCoin()
    this.getBill()
  }
}
</script>